import { baseCRUDStore } from '@common/store/crud.module';
// import { httpService } from "@common/services/http.service";

export default function(router){
    // const axiosConfig = {
    //     baseURL: config.apiUrl,
    // }
    // const httpClient = httpService(axiosConfig);

    const relUrl = ({code = false} = {}) => {
        let url = router.currentRoute.value.params.lang+'/'+router.currentRoute.value.params.project_code+'/v1/cms/content';
        if(code){
            return url+'/'+code
        }
        return url;
    };

    const baseCRUD = baseCRUDStore({
        router,
        relUrl: relUrl,
        item_key: "content_key",
        item_api_key: "code",
        item_view: 'content_edit', // "client-view" - is in router
        index_name: 'contents', // in router
        afterUpdate: () => router.push({ name: 'contents' }),
    })

    const state = {
        ...baseCRUD.state
    };

    const getters = {
        ...baseCRUD.getters,
        getContentName: (state,getters) => {
            if(getters['getActiveItem']){
                return getters['getActiveItem'].name;
            }
            return '';
        },
    }

    const actions = {
        ...baseCRUD.actions,
    };

    const mutations = {
        ...baseCRUD.mutations,
    };

    return {
        namespaced: true,
        state,
        getters,
        actions,
        mutations
    };
}
