import { baseCRUDStore } from '@common/store/crud_modal.module';

export default function(router){
    const relUrl = () => {
        let url = router.currentRoute.value.params.lang+'/'+router.currentRoute.value.params.project_code+'/v1/webur/budget';
        return url;
    };

    const baseCRUD = baseCRUDStore({
        relUrl: relUrl,
        item_api_key: "", 
    })

    const state = {
        ...baseCRUD.state
    };

    const getters = {
        ...baseCRUD.getters,
    }

    const actions = {
        fetchItems: baseCRUD.actions.fetchItems,
        filter: baseCRUD.actions.filter,
    };

    const mutations = {
        ...baseCRUD.mutations
    };

    return {
        namespaced: true,
        state,
        getters,
        actions,
        mutations
    };
}
