import Page from '@common/pages/Page.vue';
import menuRoutes from './cms/menu_routes';
import pageRoutes from './cms/content_page_routes';
import fileRoutes from './cms/file_routes';
import contentRoutes from './cms/content_routes';
import applicationRoutes from './enrollment/application_routes';
import rekapitulaceRoutes from './enrollment/rekapitulace_routes';
import budgetRoutes from './webur/budget_routes';
import blogRoutes from './blog/blog_post_routes';

export default function( locales ){
    return [
        {
            path: locales.cz.url.projects,
            name: 'projects',
            component: () => import(/* webpackChunkName: "projects" */'@admin/views/project/Projects.vue'),
            alias: [locales.en.url.projects,""],
            meta: {
                breadcrumbs: [
                    {
                        name: 'menu.projects',
                    }
                ],
                title: {i18n: 'menu.projects'},
                metaTags: [
                    {
                        name: "description",
                        content: {i18n: "meta.list_of_projects"}
                    }
                ]
            },
        },
        {
            path: locales.cz.url.project+'/:project_code',
            alias: locales.en.url.project+'/:project_code',
            component: Page,
            children: [
                {
                    path: locales.cz.url.view,
                    name: 'project',
                    component: () => import(/* webpackChunkName: "project" */'@admin/views/project/Project.vue'),
                    alias:[locales.en.url.view,""],
                    meta: {
                        breadcrumbs: [
                            {
                                name: 'menu.projects',
                                link: 'url.projects'
                            },
                            {
                                key: 'project_name',
                            },
                        ],
                        title: {store: "project/getProjectName"}
                    },
                },
                menuRoutes(locales),
                pageRoutes(locales),
                fileRoutes(locales),
                contentRoutes(locales),
                applicationRoutes(locales),
                rekapitulaceRoutes(locales),
                budgetRoutes(locales),
                blogRoutes(locales),
            ],
            
        }
    ]
}