import { baseCRUDStore } from '@common/store/crud_modal.module';
import config from 'config';
import { httpService } from "@common/services/http.service";

export default function(router){
    const axiosConfig = {
        baseURL: config.apiUrl,
    }
    const httpClient = httpService(axiosConfig);

    const relUrl = ({code = false} = {}) => {
        let url = router.currentRoute.value.params.lang+'/'+router.currentRoute.value.params.project_code+'/v1/cms/blog';
        if(code){
            return url+'/'+code
        }
        return url;
    };

    const baseCRUD = baseCRUDStore({
        relUrl: relUrl,
        item_api_key: "code", 
        afterDelete: () => {
            if(router.currentRoute.value.name === "page_edit"){
                router.push({name: "content_pages"})
            }
        }
    })

    const state = {
        ...baseCRUD.state
    };

    const getters = {
        ...baseCRUD.getters,
        getPageName: (state,getters) => {
            if(getters['getActiveItem']){
                return getters['getActiveItem'].name;
            }
            return '';
        },
    }

    const actions = {
        ...baseCRUD.actions,
        setHomepage({ commit,dispatch },{code}){
            commit('updatingRecord',{code});
            return httpClient.put(relUrl({code}),{homepage: 1})
                .then(() => {
                    commit('stopUpdatingRecord',{code});
                    commit("setHomepage",{code});
                },
                error => {
                    commit('errorUpdatingRecord',{code});
                    dispatch('alert/error', error, { root: true });
                })
        },
    };

    const mutations = {
        ...baseCRUD.mutations,
        setHomepage: (state,{code}) => {
            for(let item of state.items)
            {
                if(item.code === code){
                    item.homepage = 1;
                } else {
                    item.homepage = 0;
                }
            }
        }
    };

    return {
        namespaced: true,
        state,
        getters,
        actions,
        mutations
    };
}
