import { httpService } from "@common/services/http.service";
import config from 'config'


export function baseCRUDStore({
    relUrl,
    item_api_key = '', // "code"
    findItem = null,
    afterUpdate = () => {},
    afterCreate = () => {},
    afterDelete = () => {},
    children = false,
}){
    const axiosConfig = {
        baseURL: config.apiUrl,
    }
    const httpClient = httpService(axiosConfig);

    const indexRelUrl = ({page = 1, state ,query = {}}) => {
        let url = relUrl({state}); 
        url += '?';
        let filters = state.filters;
        for (let key in filters) {
            if (!Object.prototype.hasOwnProperty.call(filters,key)) continue; // to remove js object default properties

            if(filters[key].exact){
                url += `filter[${key}]=${filters[key].value}&`;
            } else {
                url += `filter[${key}][like]=${filters[key].value}&`;
            }
            
        }
        for (let key in query) {
            if (!Object.prototype.hasOwnProperty.call(query,key)) continue; // to remove js object default properties

            url += `${key}=${query[key]}&`;
        }
        if(page > 1){
            url += `page=${page}&`;
        }
        url = url.slice(0,-1);
        // console.log(url)
        return url;
    }

    const getCode = (code = null,record = null) => {
        let itemCode = code;
        if(code === null){
            if(!record){
                return null;
            }
            itemCode = record[item_api_key];
        }
        return itemCode
    }
    
    const _findItem = (code,items) => {
        if(findItem === null){
            for (let item of items) {
                if(item.code === code){
                    return item;
                }
            }
            return false;
        }
        return _findItem;
    };

    const _setItem = ({items,code,new_data}) => {
        let array = [];
        items.forEach(item => {
            if(children && item[children]){
                item[children] = _setItem({items: item[children],code,new_data,children});
            }
            
            if(item[item_api_key] === code){
                array.push(new_data)
            } else {
                array.push(item)
            }
        });
        return array
    };

    const state = {
        items: [],
        loading: 0,
        filters: {},
        selected_row: -1,
        page_count: 0,
        current_page: 0,
        query: {},
    }

    const getters = {
        getItems: (state) => state.items,
        getPageCount: (state) => state.page_count,
        getCurrentPageNum: (state) => state.current_page,
        getSelectedRecord: (state) => {
            if(state.selected_row >= 0 && state.data.length > state.selected_row){
                return state.data[state.selected_row];
            }
            return {};
        },
        isLoaded: (state) => state.loading === 0,
        isError: (state) => state.loading === -1,
        getFilters: state => state.filters,
        getQuery: state => state.query,
    }

    const actions = {
        fetchItems({ commit,dispatch,state },{page = 1,filter = false,query = {}}){
            if(!filter){
                commit('clearFilters');
            }
            if(!(query && Object.keys(query).length === 0 && query.constructor === Object)){
                commit('setQuery',query)
            } else if(query === null ){
                commit('setQuery',{})
            }
            // console.log(state.query)
        //    console.log(state.filters)
            // console.log(indexRelUrl({page,state,query: state.query}))
            commit('loading',1);
            return httpClient.get(indexRelUrl({page,state,query: state.query}))
                .then(response => {
                    // console.log(response.headers)
                    commit('setItems',response.data);
                    commit('setPagination',{current: response.headers['x-pagination-current-page'], count: response.headers['x-pagination-page-count']})
                    commit('loading',0);
                },
                error => {
                    commit('loading',-1);
                    dispatch('alert/error', error, { root: true });
                })
        },
        fetchItem({ commit,dispatch },{ code }){
            commit('loading',1);
            return httpClient.get(relUrl({code}))
                .then(response => {
                    commit('setItems',[response.data]);
                    commit('loading',0);
                },
                error => {
                    commit('loading',-1);
                    dispatch('alert/error', error, { root: true });
                })
        },
        updateItem({ commit,dispatch },{ data,code = null,record = null }){
            if(!data || Object.keys(data).length === 0){
                return;
            }
            let itemCode = getCode(code,record);
            if(!itemCode){
                return;
            }
            // console.log(data)
            commit('updatingRecord',{code: itemCode});
            return httpClient.put(relUrl({code: itemCode}),data)
                .then(response => {  
                    commit('setItem',{code: itemCode,new_data: response.data,children});
                    // commit('stopUpdatingRecord',{code: itemCode});
                    afterUpdate({code: itemCode});
                },
                error => {
                    commit('errorUpdatingRecord',{code: itemCode});
                    dispatch('alert/error', error, { root: true });
                });
        },
        deleteItem({ commit,dispatch },{code = null,record = null}){
            let itemCode = getCode(code,record);
            if(!itemCode){
                return;
            }
            // commit('loading',1);
            return httpClient.delete(relUrl({code: itemCode}))
                .then(() => {
                    commit('removeItem',itemCode);
                    // commit('loading',0);
                    afterDelete();
                },
                error => {
                    // commit('loading',-1);
                    dispatch('alert/error', error, { root: true });
                });
        },
        createItem({ commit,dispatch },{data}){   
            // console.log(data);      
            if(!data || Object.keys(data).length === 0){
                return;
            }
            // commit('loading',1);
            return httpClient.post(relUrl(),data)
                .then(response => {
                    commit('addItem',{data: response.data});
                    // commit('loading',0);
                    afterCreate({code: response.data[item_api_key],commit,data:response.data});
                },
                error => {
                    // commit('loading',-1);
                    dispatch('alert/error', error, { root: true });
                });
        },
        custom_action({commit,dispatch},{ code = null,record = null,custom_code,action }){
            let itemCode = getCode(code,record);
            if(!itemCode){
                return;
            }
            // commit('loading',1);
            return httpClient.get(relUrl({code: itemCode+'/'+action+'/'+custom_code})
                .then(response => {
                    commit('setItem',{code: itemCode,new_data: response.data});
                    // commit('loading',0);
                },
                error => {
                    // commit('loading',-1);
                    dispatch('alert/error', error, { root: true });
                }));
        },
        filter: ({commit,dispatch},{key,value,exact}) => {
            commit('setFilter',{key,value,exact});
            dispatch('fetchItems',{filter: true});
        }
    }

    const mutations = {
        setItems: (state,items) => state.items = items,
        setItem: (state,{ code,new_data}) => {
            state.items = _setItem({items: state.items,code,new_data});
        },
        addItem: (state,{data}) => state.items.push(data),
        loading: (state,status) => state.loading = status,
        removeItem: (state,code) => {
            let index = state.items.findIndex((item) => item[item_api_key] === code);
            if (index !== -1) {
                state.items.splice(index, 1);
            }
        },
        setFilter: (state,{key,value,exact = false}) => {
            if(value.trim() === "" && Object.prototype.hasOwnProperty.call(state.filters,key)){
                delete state.filters[key];
                return;
            }
            state.filters[key] = {exact,value};
        },
        clearFilters: (state) => state.filters = {},
        setSelectedRow: (state,index) => {
            state.selected_row = index;
        },
        updatingRecord: (state,{code}) => {
            let item = _findItem(code,state.items);
            if(!item){
                return;
            }
            item.updating = 1;
        },
        stopUpdatingRecord: (state,{code}) => {
            let item = _findItem(code,state.items);
            if(!item){
                return;
            }
            item.updating = 0;
        },
        errorUpdatingRecord: (state,{code}) => {
            let item = _findItem(code,state.items);
            if(!item){
                return;
            }
            item.updating = -1;
        },
        setPagination: (state,{count,current}) => {
            state.page_count = count;
            state.current_page = current;
        },
        setQuery: (state,query) => state.query = query,
    }
    
    return {
        namespaced: true,
        state: state,
        getters: getters,
        actions: actions,
        mutations: mutations,
        httpClient
    };

}