import { baseCRUDStore } from '@common/store/crud_modal.module';

export default function(router){
    const relUrl = () => {
        let url = router.currentRoute.value.params.lang+'/'+router.currentRoute.value.params.project_code+'/v1/cms/page-template';
        return url;
    };

    const baseCRUD = baseCRUDStore({
        relUrl: relUrl,
        item_api_key: "item_key", 
    })

    const state = {
        ...baseCRUD.state
    };

    const getters = {
        ...baseCRUD.getters,
    }

    const actions = {
        fetchItems: baseCRUD.actions.fetchItems,
    };

    const mutations = {
        ...baseCRUD.mutations
    };

    return {
        namespaced: true,
        state,
        getters,
        actions,
        mutations
    };
}
