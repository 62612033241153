import { httpService } from "@common/services/http.service";
import config from 'config';

export default function(router){
    const axiosConfig = {
        baseURL: config.apiUrl,
    }
    const httpClient = httpService(axiosConfig);

    const relUrl = ({code = false} = {}) => {
        let url = router.currentRoute.value.params.lang+'/v1/clients';
        if(code){
            return url+'/'+code
        }
        return url;
    };

    const state = {
        clients: [],
        projects: [],
        loading: 0
    }

    const getters = {
        getClients: (state) => state.clients,
        isLoaded: (state) => state.loading === 0,
        isError: (state) => state.loading === -1,
        getProjects: (state) => state.projects,
    }

    const actions = {
        fetchClients({ commit,dispatch,getters }){
            commit('loading',1);
            return httpClient.get(relUrl()+"?expand=projects")
                .then(response => {
                    commit('setClients',response.data);
                    commit('loading',0);
                    if(getters['getProjects'].length === 1 && (router.currentRoute.value.name === "projects" || router.currentRoute.value.name === "")){
                        router.push({name: "project", params: {project_code: getters['getProjects'][0].item_key}});
                    }
                },
                error => {
                    commit('loading',-1);
                    dispatch('alert/error', error, { root: true });
                })
        },
    }

    const mutations = {
        setClients: (state,clients) => { 
            state.clients = clients;
            state.projects = [];
            state.clients.forEach(client => {
                if(!Object.prototype.hasOwnProperty.call(client,'projects')){
                    return;
                }
                state.projects = state.projects.concat(client.projects);
            });
        },
        loading: (state,status) => state.loading = status,
        
    }

    return {
        namespaced: true,
        state: state,
        getters: getters,
        actions: actions,
        mutations: mutations
    };

}