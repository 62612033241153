import { computed } from 'vue';
import { useStore } from 'vuex';

export default function() {
    const store = useStore();

    const clients = computed(() => store.getters["client/getClients"]);
    const clientsLoaded = computed(() => store.getters["client/isLoaded"]);
    const clientsError = computed(() => store.getters["client/isError"]);

    const projects = computed(() => store.getters["client/getProjects"]);
    
    const project = computed(() => store.getters['project/getProject']);

    const projectLoaded = computed(() => store.getters["project/isLoaded"]);
    const projectError = computed(() => store.getters["project/isError"]);

    const hasExtension = (code) => { 
        return store.getters["project/getExtensionData"](code) ? true : false;
        // if(!project.value || !Object.prototype.hasOwnProperty.call(project.value,'extensions')){
        //     return false;
        // } 
        // let has = false;
        // project.value.extensions.forEach(extension => {
        //     if(extension.item_key === code){
        //         has = true;
        //         return;
        //     }
        // });
        // return has;
    }

    const hasPermission = (permission) => {
        return project.value.permissions && project.value.permissions[permission];
    }
    
 
    return {
        clients,
        projects,
        project,
        projectLoaded,
        projectError,
        clientsLoaded,
        clientsError,
        hasExtension,
        hasPermission
    }
}
