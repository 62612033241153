import { baseCRUDStore } from '@common/store/crud_modal.module';
import config from 'config';
import { httpService } from "@common/services/http.service";

export default function(router){
    const axiosConfig = {
        baseURL: config.apiUrl,
    }
    const httpClient = httpService(axiosConfig);

    const relUrl = ({code = false} = {}) => {
        let url = router.currentRoute.value.params.lang+'/'+router.currentRoute.value.params.project_code+'/v1/cms/file';
        if(code){
            return url+'/'+code
        }
        return url;
    };

    const baseCRUD = baseCRUDStore({
        relUrl: relUrl,
        item_api_key: "code", 
    })

    const state = {
        ...baseCRUD.state
    };

    const getters = {
        ...baseCRUD.getters,
    }

    const actions = {
        ...baseCRUD.actions,
        uploadFiles: ({dispatch,commit}) => {
            let formData = new FormData();
            let selectedFiles = document.querySelector('#input_files');
            
            selectedFiles.files.forEach(file=>{
                formData.append("files[]", file);
            });
            return httpClient.post(relUrl(),formData,{
                headers: {
                'Content-Type': 'multipart/form-data'
                }
            })
                .then((response) => {
                    for(let data of response.data){
                        commit('addItem',{data: data});
                    }
                    
                },
                error => {
                    dispatch('alert/error', error, { root: true });
                })

        }
    };

    const mutations = {
        ...baseCRUD.mutations,
    };

    return {
        namespaced: true,
        state,
        getters,
        actions,
        mutations
    };
}
