import { baseCRUDStore } from '@common/store/crud_modal.module';

export default function({relUrl}){
    const afterCreate = ({commit,data}) => {
        commit('setApplied',true);
        if(Object.prototype.hasOwnProperty.call(data,'reg_number')){
            commit('setRegNumber',data.reg_number);
        }
    }

    const baseCRUD = baseCRUDStore({
        relUrl: relUrl,
        item_api_key: "code", 
        afterCreate
    })

    const state = {
        ...baseCRUD.state,
        reg_number: '',
        applied: false,
        canApply: false
    };

    const getters = {
        ...baseCRUD.getters,
        getRegNumber: (state) => state.reg_number, 
        getCode: (state) => {
            if(state.items.length < 1){
                return '';
            }
            let item = state.items[state.items.length-1];
            if(state.items.length > 0 && Object.prototype.hasOwnProperty.call(item,'code')){
                return item.code;
            }
            return '';
        }, 
        applied: (state) => state.applied,
        canApply: (state) => state.canApply,
    }

    const actions = {
        ...baseCRUD.actions,
        getRegNumber({ commit,dispatch },{ data }){  
            // console.log(data)  
            if(!data || Object.keys(data).length === 0){
                dispatch('alert/error', "Nevyplněna všechna data", { root: true });
                return;
            }
            commit('loading',1);
            return baseCRUD.httpClient.post(relUrl()+'/registration-number',data)
                .then(response => {
                    commit('setRegNumber',response.data);
                    commit('loading',0);
                },
                error => {
                    commit('loading',0);
                    dispatch('alert/error', error, { root: true });
                });
        },
        checkCanApply({commit,dispatch}, {data}){  
            // console.log(data)  
            if(!data || Object.keys(data).length === 0){
                dispatch('alert/error', "Nevyplněna všechna data", { root: true });
                return;
            }
            // commit('loading',1);
            return baseCRUD.httpClient.post(relUrl()+'/can-apply',data)
                .then(() => {
                    commit('setCanApply',true);
                    // commit('loading',0);
                },
                error => {
                    // commit('loading',-1);
                    commit('setCanApply',false);
                    dispatch('alert/error', error, { root: true });
                });
        },
    };

    const mutations = {
        ...baseCRUD.mutations,
        setRegNumber: (state,reg_number) => state.reg_number = reg_number,
        setApplied: (state,value) => state.applied = value,
        setCanApply: (state,value) => state.canApply = value,
    };

    return {
        namespaced: true,
        state,
        getters,
        actions,
        mutations,
        baseCRUD
    };
}
