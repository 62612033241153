import Page from '@common/pages/Page.vue';

export default function( locales ){
    return {
        path: locales.cz.url.contents,
        alias: locales.en.url.contents,
        component: Page,
        children: [
            {
                path: locales.cz.url.overview,
                alias:[ locales.en.url.overview,""],
                name: "contents",
                component: () => import(/* webpackChunkName: "contents" */'@admin/views/cms/Contents.vue'),
                meta: {
                    breadcrumbs: [
                        {
                            name: 'menu.projects',
                            link: 'url.projects'
                        },
                        {
                            key: 'project_name',
                            link: 'url.project/:project_code'
                        },
                        {
                            name: 'titles.contents_overview',
                        }
                    ],
                    title: {i18n: "titles.contents_overview"}
                },
            },
            {
                path: locales.cz.url.overview+"/:page_num",
                alias:[ locales.en.url.overview+"/:page_num"],
                name: "contents_overview",
                component: () => import(/* webpackChunkName: "contents" */'@admin/views/cms/Contents.vue'),
                meta: {
                    breadcrumbs: [
                        {
                            name: 'menu.projects',
                            link: 'url.projects'
                        },
                        {
                            key: 'project_name',
                            link: 'url.project/:project_code'
                        },
                        {
                            name: 'titles.contents_overview',
                        }
                    ],
                    title: {i18n: "titles.contents_overview"}
                },
            },
            {
                path: ":content_key",
                name: "content_edit",
                component: () => import(/* webpackChunkName: "content" */'@admin/views/cms/Content.vue'),
                meta: {
                    breadcrumbs: [
                        {
                            name: 'menu.projects',
                            link: 'url.projects'
                        },
                        {
                            key: 'project_name',
                            link: 'url.project/:project_code'
                        },
                        {
                            name: 'titles.contents_overview',
                            link: 'url.project/:project_code/url.contents'
                        },
                        {
                            store: "content/getContentName"
                        }
                    ],
                    title: {store: "content/getContentName"},
                    editor: true
                },
            },
        ]
    };
}