export default function(){
    const state = {
    };

    const getters = {
        project_name: (state,getters, rootState, rootGetters) => rootGetters['project/getProjectName'],
        menu_name: (state,getters, rootState, rootGetters) => rootGetters['menu_item/getMenuName'],
    }

    const actions = {
    };

    const mutations = {
    };

    return {
        namespaced: true,
        state,
        getters,
        actions,
        mutations
    };
}
