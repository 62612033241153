export default function( locales ){
    return {
        path: locales.cz.url.rekapitulace,
        alias: locales.en.url.rekapitulace,
        name: "enrollment-rekapitulace",
        component: () => import(/* webpackChunkName: "rekapitulace" */'@admin/views/enrollment/Rekapitulace.vue'),
        meta: {
            breadcrumbs: [
                {
                    name: 'menu.projects',
                    link: 'url.projects'
                },
                {
                    key: 'project_name',
                    link: 'url.project/:project_code'
                },
                {
                    name: 'titles.rekapitulace_zadosti',
                }
            ],
            title: {i18n: "titles.rekapitulace_zadosti"}
        },
    };
}