import { logout,isLoggedIn,getToken } from '@common/helpers';
import { httpService } from "./http.service";

export function authService(config){
    const axiosConfig = {
        baseURL: config.apiAuthUrl,
    }
    const httpClient = httpService(axiosConfig);

    async function login(formData) {
        return httpClient.post('/auth/login', formData)
            .then(response => {
                // login successful if there's a jwt token in the response
                if (response.data.access_token) {
                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                    localStorage.setItem('ACCESS_TOKEN', response.data.access_token)
                }    
                return response.data;
            });
    }

    async function signup(formData) {
        return httpClient.post('/auth/signup', formData)
            .then(response => {
                // if (response.data.access_token) {
                //     localStorage.setItem('ACCESS_TOKEN', response.data.access_token)
                // }    
                return response.data;
            });
    }

    
    async function request_reset_password(formData) {
        return httpClient.post('/auth/request-password-reset', formData)
            .then(response => { 
                return response.data;
            });
    }
    async function reset_password(token,formData) {
        return httpClient.post('/auth/reset-password?token='+token, formData)
            .then(response => { 
                return response.data;
            });
    }
    async function resend_confirmation(formData) {
        return httpClient.post('/auth/resend-verification-email', formData)
            .then(response => { 
                return response.data;
            });
    }
    async function verify_email(token) {
        return httpClient.get('/auth/verify-email?token='+token)
            .then(response => { 
                return response.data;
            });
    }
    
    
    async function getUser() {
        return httpClient.get('/user/data')
            .then(response => {
                return response.data;
            })
        // try {
        //     const {status, data} = await httpClient.get('/user/data');
        //     if (status === 200) {
        //         return data;
        //     }
        // } catch (e) {
        //     return null;
        // }

        // return null;
    }




    return {
        login,
        logout,
        signup,
        isLoggedIn,
        getToken,
        getUser,
        reset_password,
        resend_confirmation,
        request_reset_password,
        verify_email
    }
}
