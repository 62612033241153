import { createApp } from 'vue'
import App from './App.vue'
// import '@common/registerServiceWorker'
import { setupI18n } from './i18n'
import { setupRouter } from './router'
import { setupStore } from './store'

// import upperFirst from 'lodash/upperFirst'
// import camelCase from 'lodash/camelCase'

import { registerBaseComponents } from '@common/helpers';
import { registerProjectComponents } from '@admin/helpers';
import { clickOutsideDirective } from "@common/directives";

const i18n = setupI18n();
const router = setupRouter(i18n);
const store = setupStore(router);


let app = createApp(App);

app.use(store);

app.use(i18n);
app.use(router);

//app.use(axios)

registerBaseComponents(app);
registerProjectComponents(app);
app.directive('click-outside',clickOutsideDirective());

// todo  - require context problem
// const requireComponent = require.context(
//     './components/base',
//     false,
//     /Base[A-Z]\w+\.(vue|js)$/
//   )
  
//   requireComponent.keys().forEach(fileName => {
//     const componentConfig = requireComponent(fileName)
//     const componentName = upperFirst(
//       camelCase(
//         fileName
//           .split('/')
//           .pop()
//           .replace(/\.\w+$/, '')
//       )
//     )
//     app.component(
//       componentName,
//       componentConfig.default || componentConfig
//     )
//   })


router.isReady().then(() => {
    app.mount('#app');
})
