import { httpService } from "@common/services/http.service";
import config from 'config';

let newContentID = 0;

export default function(router){
    const axiosConfig = {
        baseURL: config.apiUrl,
    }
    const httpClient = httpService(axiosConfig);
    
    const relPageUrl = (action = null) => {
        if(!router.currentRoute.value.params.page_code){
            console.log("Unknown page");
            return;
        }         
        
        let url = router.currentRoute.value.params.lang+'/'+router.currentRoute.value.params.project_code+'/v1/cms/';
        if(router.currentRoute.value.name === 'blog_post'){
            url += 'blog';
        } else {
            url += 'content-page';
        }
        if(action){
            url += '/'+action;
        }
        url += '/'+router.currentRoute.value.params.page_code;
        return url;
    };
    
    const getContents = (state,placementCode) => {
        let _contents = [];

        for(let _content of state.tempPage.contents){
            if(_content.placementCode === placementCode){
                _contents.push(Object.assign({},_content));
            }
        }
        _contents.sort((a, b) => (a.position > b.position) ? 1 : -1)
        return _contents;
    }

    const getNewContentCode = () => {
        newContentID++;
        return "newContent"+newContentID.toString();
    }

    const getNewPosition = (state,placementCode) => {
        let placementContents = getContents(state,placementCode);
        let newPosition = 0;
        for(let placementContent of placementContents){
            if(newPosition <= placementContent.position){
                newPosition = placementContent.position+1;
            }
        }
        return newPosition;
    }

    const state = {
        page: {},
        loading: 0,
        tempPage: {},
        unsaved: false,
    }
    
    const getters = {
        getPage: (state) => state.page,
        isLoaded: (state) => state.loading === 0,
        isError: (state) => state.loading === -1,
        getPageName: (state) => {
            if(state.page.title){
                return state.page.title;
            }
            return '';
        },
        getTempPage: (state) => state.tempPage,
        getContents: (state) => (placementCode) => getContents(state,placementCode),
        isUnsaved: (state) => state.unsaved,
    }
    
    const actions = {
        fetchItem({ commit,dispatch }){
            commit('loading',1);
            return httpClient.get(relPageUrl()+"?expand=contents,template")
                .then(response => {
                    commit('setPage',response.data);
                    commit('clearTempPage');
                    // commit('setContents',response.data.contents);
                    // commit('setTemplate',response.data.template);
                    commit('loading',0);
                },
                error => {
                    commit('loading',-1);
                    dispatch('alert/error', error, { root: true });
                })
        },
        updateItem({ dispatch,commit },{ data }){
            return httpClient.put(relPageUrl(),data)
                .then((response) => {
                    commit('setPage',response.data); 
                },
                error => {
                    dispatch('alert/error', error, { root: true });
                })
        },
        deleteItem({ dispatch }){
            return httpClient.delete(relPageUrl())
                .then(() => {
                    router.push({ name: 'content_pages' });  
                },
                error => {
                    dispatch('alert/error', error, { root: true });
                });
        },
        // addContent({ commit,getters },{ data,placement_key }){
        //     let page = getters['getPage'];
        //     if(!page){
        //         return;
        //     }
        //     data = Object.assign(data,{
        //         content_page: page.code,
        //         page_placement: placement_key
        //     })            
        //     commit('addContent',{content: data,placement_key});  
        //     // return httpClient.post(relContentUrl(),data)
        //     //     .then((response) => {
        //     //         commit('addContent',{content: response.data,placement_key});  
        //     //     },
        //     //     error => {
        //     //         dispatch('alert/error', error, { root: true });
        //     //     });
        // },
        pageEdit({ dispatch,state,commit }){
            let data = state.tempPage;
            if(data && Object.keys(data).length === 0 && data.constructor === Object){
                return;
            }
            commit('loading',1);
            return httpClient.post(relPageUrl('page-edit'),data)
                .then((response) => {
                    commit('setPage',response.data);
                    commit('clearTempPage');
                    commit('loading',0);
                    dispatch('alert/successI18n', 'controls.saved', { root: true });
                },
                error => {
                    dispatch('alert/error', error, { root: true });
                    commit('loading',0);
                })
        },
    }

    const mutations = {
        setPage: (state,page) => state.page = page,
        loading: (state,status) => state.loading = status,
        clearTempPage: (state) => {
            state.tempPage = Object.assign({},state.page);
            state.unsaved = false;
        },
        updateValues: (state,{values}) => {
            state.tempPage = Object.assign(state.tempPage,values);
            state.page = Object.assign(state.page,values);
        },
        updateContent: (state,{data}) => {
            let _contents = [];
            for(let _content of state.tempPage.contents){
                if( _content.content.code === data.content.code){
                    _content.content = data.content;
                    _content.content.edited = true;
                    if(!Array.isArray(_content.status)){
                        _content.status = [];
                    }
                    _content.status.push('edited')
                }
                _contents.push(Object.assign({},_content));
            }
            state.tempPage.contents = _contents;
            state.unsaved = true;
        },
        addContent: (state,{placementCode,data}) => {
            let content = {
                placementCode,
                position: getNewPosition(state,placementCode),
                status: data.code ? ['added'] : ['new'],
                content: {
                    code: data.code ?? getNewContentCode(),
                    data: data.data ?? "[{}]",
                    typeCode: data.typeCode,
                    type: data.type,
                    pagesNum: data.pagesNum ?? 0,
                    name: data.name ?? "content",
                },
            }
            state.tempPage.contents.push(content);
            state.unsaved = true;
        },
        setPosition: (state,{data}) => {
            let _contents = [];
            for(let _content of state.tempPage.contents){
                let new_content = Object.assign({},_content);
                if(_content.placementCode === data.new_placement){
                    if(_content.position > data.new_index && data.new_index >= 0){
                        new_content.position++;
                    }
                    if(data.old_placement !== data.new_placement || (data.old_placement === data.new_placement && data.old_index > data.new_index)){
                        if(_content.position === data.new_index){
                            new_content.position++;
                        }
                    }
                }
                if(_content.placementCode === data.old_placement){
                    if(_content.position === data.old_index){
                        if(data.new_index < 0){
                            new_content.position = getNewPosition(state,data.new_placement);
                        } else {
                            new_content.position = data.new_index;
                        }
                        new_content.placementCode = data.new_placement;
                        
                        if(!Array.isArray(new_content.status)){
                            new_content.status = [];
                        }
                        new_content.status.push('moved')
                    } else if (_content.position > data.old_index){
                        new_content.position--;
                    }
                }
                _contents.push(new_content);
            }
            state.tempPage.contents = _contents;
            state.unsaved = true;
        },
        contentDeleted: (state,{code}) => {
            state.tempPage.contents = state.tempPage.contents.filter((content) => content.content.code !== code);
        },
        contentRemoved: (state,{placementCode,index}) => {
            state.tempPage.contents = state.tempPage.contents.filter((content) => content.placementCode !== placementCode || content.position !== index);
            state.unsaved = true;
        },
        makeContentCopy: (state,{placementCode,index}) => {
            let _content = state.tempPage.contents.find((content) => content.placementCode === placementCode && content.position === index);
            if(!_content){
                console.log(state.tempPage.contents)
                return;
            }
            _content.content.code = getNewContentCode();
            _content.content.pagesNum = 0;
            _content.status = ['new'];
            state.unsaved = true;
        },
        setUnsaved: (state,value = true) => state.unsaved = value,
    }

    
    return {
        namespaced: true,
        state: state,
        getters: getters,
        actions: actions,
        mutations: mutations,
    };
}