import { createStore } from "vuex";
import config from 'config';

import alert  from '@common/store/alert.module';
import Account  from '@common/store/account.create';
import MenuItem  from './modules/cms/menu_item.module';
import Project  from './modules/project.module';
import Client  from './modules/client.module';
import Menu  from './modules/cms/menu.module';
import Breadcrumbs  from './modules/breadcrumbs.module';
import ContentPage  from './modules/cms/content_page.module';
import Language  from './modules/cms/language.module';
import Files  from './modules/cms/file.module';
import Content  from './modules/cms/content.module';
import ContentType  from './modules/cms/content_type.module';
import PageEditor  from './modules/cms/page_editor.module';
import PageTemplate  from './modules/cms/page_template.module';
import Application  from './modules/enrollment/application.module';
import School  from '@common/store/enrollment/school.module';
import Budget  from './modules/webur/budget.module';
import Organization  from './modules/webur/organization.module';
import BlogPost  from './modules/blog/blog_post.module';


export function setupStore(router){
    return createStore({
        state: {},
        mutations: {},
        actions: {},
        modules: {
            alert: alert,
            account: Account(router,config),
            menu_item: MenuItem(router),
            project: Project(router),
            client: Client(router),
            menu: Menu(router),
            breadcrumbs: Breadcrumbs(router),
            content_page: ContentPage(router),
            language: Language(router),
            file: Files(router),
            content: Content(router),
            content_type: ContentType(router),
            page_editor: PageEditor(router),
            page_template: PageTemplate(router),
            application: Application(router),
            school: School({relUrl: () => router.currentRoute.value.params.lang+'/'+router.currentRoute.value.params.project_code+'/v1/enrollment/school'}),
            budget: Budget(router),
            organization: Organization(router),
            blog_post: BlogPost(router),
        }
    });
}
