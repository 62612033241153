import { baseCRUDStore } from '@common/store/crud_modal.module';
import { httpService } from "@common/services/http.service";
import config from 'config';

export default function(router){
    const axiosConfig = {
        baseURL: config.apiUrl,
    }
    const httpClient = httpService(axiosConfig);

    const relUrl = ({code = null} = {}) => {
        let url = router.currentRoute.value.params.lang+'/'+router.currentRoute.value.params.project_code+'/'+router.currentRoute.value.params.menu_key+'/v1/cms/menu-item';
        if(code){
            url += '/'+code;
        }
        return url;
    };
    const relMenuUrl = () => {
        let url = router.currentRoute.value.params.lang+'/'+router.currentRoute.value.params.project_code+'/v1/cms/menu/'+router.currentRoute.value.params.menu_key+'?expand=menu_items';
        return url;
    };

    const findItem = (code,items) => {
        for (let item of items) {
            if(item.code === code){
                return item;
            }
            if(item.menu_items.length > 0){
                let found = findItem(code,item.menu_items);
                if(found){
                    return found;
                }
            }
        }
        return false;
    };
    const removeItem = (code,items) => {
        for( let i = 0; i < items.length; i++){ 
            let item = items[i];
            if ( item.code === code) {
                items.splice(i, 1); 
                return item;
            }
            if(item.menu_items.length > 0){
                let removed = removeItem(code,item.menu_items);
                if(removed){
                    return removed;
                }
            }
        }
        return false;
    };

    const setItemDragged = ({items,value,code = null}) => {
        for (let item of items) {
            if(item.code === code){
                item.dragged = value;
            } else {
                item.dragged = false;
            }
            setItemDragged({items: item.menu_items,value,code});
        }
    }

    const baseCRUD = baseCRUDStore({
        relUrl: relUrl,
        item_api_key: "code", 
        findItem,
        children: 'menu_items'
    })

    const state = {
        ...baseCRUD.state,
        menuName: ''
    };

    const getters = {
        ...baseCRUD.getters,
        getMenuName: (state) => state.menuName,
        getItem: (state) => (code) => {
            return findItem(code,state.items);
        }
    }

    const actions = {
        fetchItems({ commit,dispatch }){
            commit('loading',1);
            return httpClient.get(relMenuUrl())
                .then(response => {
                    if(response.data.menu_items){
                        commit('setItems',response.data.menu_items);
                    } else {
                        commit('setItems',[]);
                    }
                    commit('setMenu',response.data);
                    
                    commit('loading',0);
                },
                error => {
                    commit('loading',-1);
                    dispatch('alert/error', error, { root: true });
                })
        },
        updateItem: baseCRUD.actions.updateItem,
        deleteItem: baseCRUD.actions.deleteItem,
        createItem: baseCRUD.actions.createItem,
        changePlace({ commit, dispatch }, { itemCode, parentCode, position}){
            let url = relUrl()+'/change-place?code='+itemCode+'&position='+position;
            if(parentCode){
                url += '&parent='+parentCode;
            }
            // console.log(url)
            // commit('updatingRecord',{code: itemCode});
            return httpClient.get(url)
                .then(() => {
                    commit('changePlace',{ itemCode, parentCode, position });
                    commit('stopDrag');
                    // commit('stopUpdatingRecord',{code: itemCode});
                },
                error => {
                    dispatch('alert/error', error, { root: true });
                })

        }
    };

    const mutations = {
        ...baseCRUD.mutations,
        addItem: (state,{data}) => {
            let parent = findItem(data.parentCode,state.items);
            if(parent){
                parent.menu_items.push(data);
            } else {
                state.items.push(data)
            }
        },
        removeItem: (state,code) => removeItem(code,state.items),
        setMenu: (state,menu) => {
            state.menuName = menu.name;
        },
        changePlace: (state,{ itemCode, parentCode, position }) => {
            let item = removeItem(itemCode,state.items);
            if(!item){
                console.warn('Unknown menu element.');
                return false;
            }
            // item.updating = true;
            let parent = findItem(parentCode,state.items);
            if(parent){
                parent.menu_items.splice(position,0,item);
            } else {
                state.items.splice(position,0,item);
            }
        },
        stopUpdatingRecord: (state,{itemCode}) => {
            let item = findItem(itemCode,state.items);
            if(!item){
                return false;
            }
            item.updating = false;
        },
        startDrag: (state,code) => {
            setItemDragged({items: state.items,value: true,code});
        },
        stopDrag: (state) => {
            setItemDragged({items: state.items,value: false});
        }
    };

    return {
        namespaced: true,
        state,
        getters,
        actions,
        mutations
    };
}
