<template>
    <div class="layout">
        <MainHeader id="main-header" @toggle-menu="toggleMenu()"/>
        <SideMenu id="side-menu" :show_menu="showSideMenu"/>
        <div id="main-container" v-bind="$attrs">
            <Alert />
            <Breadcrumbs />
            <router-view id="main-content" v-bind="$attrs"/>
        </div>
        <MainFooter id="main-footer" />
    </div>
</template>

<script>
import Alert from "@common/components/Alert";
import MainHeader from "@admin/components/layout/MainHeader";
import MainFooter from "@admin/components/layout/MainFooter";
import SideMenu from "@admin/components/layout/SideMenu";
import Breadcrumbs from "@common/components/base/Breadcrumbs";
import { ref, watchEffect } from "vue";
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

export default {
    name: "Layout",
    components: {
        Alert,
        MainHeader,
        MainFooter,
        SideMenu,
        Breadcrumbs
    },
    setup(){
        const showSideMenu = ref(false);
        
        const store = useStore();

        const router = useRouter();

        store.dispatch("client/fetchClients");

        const fetchProject = () => {
            if(router.currentRoute.value.params.project_code){
                store.dispatch("project/fetchProject",{project_code: router.currentRoute.value.params.project_code})
            }
            
        };

        watchEffect(
            () => fetchProject()
        );

        const toggleMenu = () => {
            showSideMenu.value = !showSideMenu.value;
        }

        return {
            toggleMenu,
            showSideMenu
        }
    }
}
</script>

<style scoped>
.layout{
    display: grid;
    grid-template-columns: max-content 1fr;
    grid-template-rows: max-content minmax(max-content,1fr) max-content;
    grid-template-areas: 
        'header header'
        'side container'
        'footer footer'
        ;
    grid-gap: 0;
    justify-items: stretch; 
    min-height: 100vh;
    min-height: 100vh;    
    /* overflow: auto; */
}

#main-header {
    grid-area: header;
}
#side-menu {
    grid-area: side;
    z-index: 1;
}


#main-container{
    grid-area: container;
    display: flex;
    flex-direction: column;
    width: 100%;
}

#main-content{
    min-height: calc(100% - 2.5rem);
    background: white;
    box-shadow: 0px 2px 8px 1px grey;
    margin: 0 auto;
    padding: 0;
    min-width: 50rem;
    max-width: 100%;
}

#main-content>::v-deep(h1),::v-deep(.loaded-content#main-content>div>h1){
    margin: 0;
    padding: 0.5rem 1rem;
    background: #ffbe46;
    background: linear-gradient(-30deg, #ffbe46 0%, #f3cf8c 33%, #ffbe46 100%);
    box-shadow: inset 1px 1px 15px 5px #ffbe46;
    font-size: 1.6rem;
}

#main-footer{
  grid-area: footer;
}

@media only screen and (max-width: 1200px) {
    #main-content{
        min-width: initial;
        width: 100%;
    }
}

@media only screen and (max-width: 800px) {
    #main-container{
        grid-area: side / side / side / container;
    }
}
</style>
