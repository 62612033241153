import { baseCRUDStore } from '@common/store/crud_modal.module';
import config from 'config';

export default function({relUrl}){

    const baseCRUD = baseCRUDStore({
        config,
        relUrl
    })

    const state = {
        ...baseCRUD.state
    };

    const getters = {
        ...baseCRUD.getters,
    }

    const actions = {
        fetchItems: baseCRUD.actions.fetchItems
    };

    const mutations = {
        ...baseCRUD.mutations
    };

    return {
        namespaced: true,
        state,
        getters,
        actions,
        mutations
    };
}
