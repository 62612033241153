export function clickOutsideDirective(){
    return {
        mounted: function (el, binding) {
            el.clickOutsideEvent = function (event) {
                if (!(el == event.target || el.contains(event.target))) {
                    binding.value(event);
                }
            };
            
            setTimeout(() => document.body.addEventListener('click', el.clickOutsideEvent),500)
        },
        unmounted: function (el) {
            setTimeout(() => document.body.removeEventListener('click', el.clickOutsideEvent),500)
        },
    }
}