import Application from '@common/store/enrollment/application.module';

export default function(router){
    const relUrl = ({code = false} = {}) => {
        let url = router.currentRoute.value.params.lang+'/'+router.currentRoute.value.params.project_code+'/v1/enrollment/application';
        if(code){
            return url+'/'+code
        }
        // url += "?"
        // if(state.sortBy){
        //     url += "sort="+state.sortBy+"&";
        // }
        // if(state.withDiscarded){
        //     url += "discarded="+state.withDiscarded+"&";
        // }
        // url = url.slice(0,-1);
        // console.log(url)

        return url;
    };

    const application = Application({relUrl});

    const state = {
        ...application.state,
        recapitulation: {},
        filteredRecapitulation: {},
        // sortBy: 0, 
        // withDiscarded: 0,
    };

    const getters = {
        ...application.getters,
        getRecapitulation: (state) => state.recapitulation,
        getFilteredRecapitulation: (state) => state.filteredRecapitulation,
        // getSortBy: (state) => state.sortBy,
        // getWithDiscarded: (state) => state.withDiscarded,
    }

    const actions = {
        ...application.actions,
        fetchRecapitulation({ commit,dispatch }){  
            commit('loading',1);
            return application.baseCRUD.httpClient.get(relUrl()+'/recapitulation')
                .then(response => {
                    commit('setRecapitulation',response.data);
                    commit('setFilteredRecapitulation',response.data);
                    commit('loading',0);
                },
                error => {
                    commit('loading',-1);
                    dispatch('alert/error', error, { root: true });
                });
        },
        fetchFilteredRecapitulation({ commit,dispatch }, { status,pohlavi }){  
            commit('loading',1);
            let url = relUrl()+'/recapitulation?';
            if(status){
                url += 'filter[status]='+status+'&';
            }
            if(pohlavi){
                url += 'filter[pohlavi]='+pohlavi+'&';
            }
            url = url.slice(0,-1);
            return application.baseCRUD.httpClient.get(url)
                .then(response => {
                    commit('setFilteredRecapitulation',response.data);
                    commit('loading',0);
                },
                error => {
                    commit('loading',-1);
                    dispatch('alert/error', error, { root: true });
                });
        },
    };

    const mutations = {
        ...application.mutations,
        setRecapitulation: (state,data) => state.recapitulation = data,
        setFilteredRecapitulation: (state,data) => state.filteredRecapitulation = data,
        // setSortBy: (state,data) => state.sortBy = data,
        // setWithDiscarded: (state,data) => state.withDiscarded = data,
    };

    return {
        namespaced: true, 
        state,
        getters,
        actions,
        mutations
    };
}
