import { setupRouterBase } from '@common/router';
import Layout from '@admin/views/Layout.vue';
import projectRoutes from './project_routes';
//import i18n from '../i18n'

//import Home from '@admin/views/Home.vue'


export function setupRouter(i18n){
    const locales = {};
    locales.cz = i18n.global.getLocaleMessage('cz');
    locales.en = i18n.global.getLocaleMessage('en');

    const routesLocale = [
        {
            path: locales.cz.url.login,
            name: 'login',
            component: () => import(/* webpackChunkName: "login-page" */`../views/LoginPage.vue`),
            alias: locales.en.url.login,
            meta: {
                public: true
            }
        },
        {
            path: '',
            component: Layout,
            children: [
                ...projectRoutes(locales)
            ]
        },
        
    ]


    return setupRouterBase({routes:routesLocale,i18n})
}
