import Page from '@common/pages/Page.vue';

export default function( locales ){
    return   {
        path: locales.cz.url.menus,
        alias: locales.en.url.menus,
        component: Page,
        children: [
            {
                path: locales.cz.url.select,
                alias:[ locales.en.url.select,""],
                // name: 'menus',
                component: () => import(/* webpackChunkName: "menus" */'@admin/views/cms/Menus.vue'),
                meta: {
                    breadcrumbs: [
                        {
                            name: 'menu.projects',
                            link: 'url.projects'
                        },
                        {
                            key: 'project_name',
                            link: 'url.project/:project_code'
                        },
                        {
                            name: 'titles.select_menus',
                        }
                    ],
                    title: {i18n: "titles.select_menus"}
                },
            },
            {
                path: ":menu_key",
                // name: 'menu_items',
                component: () => import(/* webpackChunkName: "menu-items" */'@admin/views/cms/MenuItems.vue'),
                meta: {
                    breadcrumbs: [
                        {
                            name: 'menu.projects',
                            link: 'url.projects'
                        },
                        {
                            key: 'project_name',
                            link: 'url.project/:project_code'
                        },
                        {
                            name: 'menu.menus',
                            link: 'url.project/:project_code/url.menus'
                        },
                        {
                            key: "menu_name"
                        }
                    ],
                    title: {store: "menu/getMenuName"}
                },
            }
        ]
    };

}