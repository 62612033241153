<template>
    <ul class="side-menu" :class="{'mobile-show': show_menu}">
        <LoadedListContent v-if="projects.length > 1" :loaded="clientsLoaded" :error="clientsError">            
            <li class="label">{{ $t('menu.projects') }}</li>
            <li>
                <ul>
                    <li><RouterLinkLocal :link="'url.projects'">{{ $t('menu.overview') }}</RouterLinkLocal></li>
                </ul>
            </li>
        </LoadedListContent>
        <template v-if="isProjectActive && hasExtension('blog_1') && hasPermission('moderateProject')">
            <LoadedListContent :loaded="projectLoaded" :error="projectError">
                <li class="label">{{ $t('menu.blog') }}</li>
                <li>
                    <ul>
                        <!-- <li><RouterLinkLocal :link="'url.project/:project_code/url.menus'">{{ $t('menu.create_post') }}</RouterLinkLocal></li> -->
                        <li><RouterLinkLocal :link="'url.project/:project_code/url.blog_posts'">{{ $t('menu.list_of_post') }}</RouterLinkLocal></li>
                    </ul>
                </li>
            </LoadedListContent>
        </template>
        <template v-if="isProjectActive && hasExtension('cms_base_1') && hasPermission('moderateProject')">
            <LoadedListContent :loaded="projectLoaded" :error="projectError">
                <li class="label">{{ $t('menu.web_content') }}</li>
                <li>
                    <ul>
                        <li><RouterLinkLocal :link="'url.project/:project_code/url.menus'">{{ $t('menu.menus') }}</RouterLinkLocal></li>
                        <li><RouterLinkLocal :link="'url.project/:project_code/url.content_pages'">{{ $t('menu.pages') }}</RouterLinkLocal></li>
                        <li><RouterLinkLocal :link="'url.project/:project_code/url.files'">{{ $t('menu.files') }}</RouterLinkLocal></li>
                    </ul>
                </li>
            </LoadedListContent>
        </template>
        <template v-if="isProjectActive && hasExtension('enrollment_1')">
            <LoadedListContent :loaded="projectLoaded" :error="projectError">
                <li class="label"  v-if="hasPermission('moderateProject')">{{ $t('menu.enrollment') }}</li>
                <li>
                    <ul>
                        <li v-if="hasPermission('editApplication')"><RouterLinkLocal :link="'url.project/:project_code/url.sprava_zadosti'">{{ $t('menu.sprava_zadosti') }}</RouterLinkLocal></li>
                        <li><RouterLinkLocal :link="'url.project/:project_code/url.rekapitulace'">{{ $t('menu.rekapitulace') }}</RouterLinkLocal></li>
                    </ul>
                </li>
            </LoadedListContent>
        </template>
        <template v-if="isProjectActive && hasExtension('webur_1')">
            <LoadedListContent :loaded="projectLoaded" :error="projectError">
                <li class="label"  v-if="hasPermission('moderateProject')">WebUR</li>
                <li>
                    <ul>
                        <li><RouterLinkLocal :link="'url.project/:project_code/url.budget'">{{ $t('menu.budget') }}</RouterLinkLocal></li>
                    </ul>
                </li>
            </LoadedListContent>
        </template>
    </ul>
</template>

<script>
import useProject from '@admin/composable/ProjectModel';
import LoadedListContent from "@common/components/base/LoadedListContent";
import { computed } from 'vue';
import { useRouter } from 'vue-router';

export default {
    name: "SideMenu",
    props: {
        show_menu: {
            type: Boolean,
            default: () => true,
        }
    },
    components: {
        LoadedListContent
    },
    setup(){
        const { 
            projects,
            projectLoaded,
            projectError,
            clientsLoaded,
            clientsError,
            hasExtension,
            hasPermission
        } = useProject(); 

        const router = useRouter();
        const isProjectActive = computed(() => {
            if(router.currentRoute.value.params['project_code']){
                return true;
            }
            return false;
        })

        return {
            projects,
            projectLoaded,
            projectError,
            clientsLoaded,
            clientsError,
            hasExtension,
            hasPermission,
            isProjectActive
        }
    }
}
</script>

<style scoped>
.side-menu{
    background: #e9fff3;
    margin: 0 0.5rem 0 0;
    padding: 0.5rem;
    width: 12rem;
    box-shadow: 0px 2px 8px 1px grey;
}
.side-menu ul{
    margin: 0;
    padding: 0;
}
.side-menu a{
    display: inline-block;
    padding: 0.5rem;
    color: #163452;
    color: var(--dark-color);
    width: 100%;
    transition: background var(--transition-ease);
}

.side-menu ul li a{
    padding: 0.5rem 0.5rem 0.5rem 2rem;
}
.side-menu a:hover{
    background: #bfecc5;
}
.side-header a.router-link-exact-active {
    background: #f1bd80;
    background: var(--secondary-color);
}
.side-menu>li{
    border-bottom: 2px solid var(--primary-color);
}
.label{
    background: var(--primary-color);
    padding: 0.3rem 1rem;
    color: white;
}
.loader{
    text-align: center;
    padding: 1rem;
}
.loader .error{
    height: 1rem;
}


@media only screen and (max-width: 800px) {
    .side-menu{
        display: none;
    }
    .side-menu.mobile-show{
        display: block;
    }
}


</style>
