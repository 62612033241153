<template>
    <div class="main-footer">.</div>
</template>

<script>
export default {
    name: "MainFooter"
}
</script>

<style scoped>
.main-footer{
    background: rgb(201,201,201);
    background: linear-gradient(0deg, rgba(201,201,201,1) 0%, rgba(235,235,235,1) 33%, rgba(201,201,201,1) 100%);
    border-top: 1px solid rgb(191, 191, 191);
}

</style>
