import { authService } from '../services/index';
import config from 'config'

export default function(router){
    const userService = authService(config);

    const definedActions = {
        afterLogin: () => router.push('/'),
    }

    const user = JSON.parse(localStorage.getItem('user'));
    const state = user
        ? { status: { loggedIn: true }, user }
        : { status: {}, user: null };
    
    const getters = {
        loggedIn: (state) => state.status.loggedIn ? true : false,
        registered: (state) => state.status.registered ? true : false,
        user: (state) => state.user,
        username: (state) => state.user !== null ? state.user.username : '',
        status: (state) => state.status,
    }
    
    const actions = { 
        login({ dispatch, commit }, formData) {
            commit('loginRequest', { username: formData.username });
        
            userService.login(formData)
                .then(
                    user => {
                        commit('loginSuccess', user);
                        definedActions.afterLogin();
                    },
                    error => {
                        commit('loginFailure', error);
                        dispatch('alert/error', error, { root: true });
                    }
                );
        },
        logout({ commit }) {
            userService.logout();
            commit('logout');
            //router.push({name: 'login'});
        },
        signup({ dispatch, commit }, user) {
            commit('registerRequest', user);
        
            userService.signup(user)
                .then(
                    user => {
                        commit('registerSuccess', user);
                        router.push({name: 'login'});
                            // display success message after route change completes
                            
                        setTimeout(() => {
                            dispatch('alert/success', 'Registration successful. Confirmation email has been sent.', { root: true });
                        })
                    },
                    error => {
                        commit('registerFailure', error);
                        dispatch('alert/error', error, { root: true });
                    }
                );
        },
        request_reset_password({dispatch,},{ email }){
            userService.request_reset_password({ email })
                .then(
                    () => {
                        router.push({name: 'login'});
                            // display success message after route change completes
                        setTimeout(() => {
                            dispatch('alert/success', 'Email has been sent', { root: true });
                        })
                    },
                    error => {
                        dispatch('alert/error', error, { root: true });
                    }
                );
        },
        reset_password({dispatch,},formData){
            console.log(router.currentRoute.value.params)
            let token = router.currentRoute.value.params.token;
            userService.reset_password(token,formData)
                .then(
                    () => {
                        router.push({name: 'login'});
                            // display success message after route change completes
                            
                        setTimeout(() => {
                            dispatch('alert/success', 'Password has been changed', { root: true });
                        })
                    },
                    error => {
                        dispatch('alert/error', error, { root: true });
                    }
                );
        },
        resend_confirmation({dispatch,},{ email }){
            userService.resend_confirmation({ email })
                .then(
                    () => {
                        router.push({name: 'login'});
                            // display success message after route change completes
                            
                        setTimeout(() => {
                            dispatch('alert/success', 'Confirmation email has been resent', { root: true });
                        })
                    },
                    error => {
                        dispatch('alert/error', error, { root: true });
                    }
                );
        },
        verify_email({dispatch}){
            let token = router.currentRoute.value.params.token;
            userService.verify_email(token)
                .then(
                    () => {
                        router.push({name: 'login'});
                        
                        setTimeout(() => {
                            dispatch('alert/success', 'Email has been verified', { root: true });
                        })
                    },
                    error => {
                        router.push({name: 'login'});
                        
                        setTimeout(() => {
                            dispatch('alert/error', error, { root: true });
                        })
                    }
                );
        }
    };
    
    const mutations = {
        loginRequest(state, user) {
            state.status = { loggingIn: true };
            state.user = user;
        },
        loginSuccess(state, user) {
            state.status = { loggedIn: true };
            state.user = user;
        },
        loginFailure(state) {
            state.status = {};
            state.user = null;
        },
        logout(state) {
            state.status = {};
            state.user = null;
        },
        registerRequest(state) {
            state.status = { registering: true };
        },
        registerSuccess(state) {
            state.status = { registered: true };
        },
        registerFailure(state) {
            state.status = {};
        }
    };
    
    return {
        namespaced: true,
        state,
        actions,
        mutations,
        getters,
        definedActions
    };
}
