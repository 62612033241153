import Page from '@common/pages/Page.vue';

export default function( locales ){
    return {
        path: locales.cz.url.content_pages,
        alias: locales.en.url.content_pages,
        component: Page,
        children: [
            {
                path: locales.cz.url.overview,
                alias:[ locales.en.url.overview,""],
                name: "content_pages",
                component: () => import(/* webpackChunkName: "content-pages" */'@admin/views/cms/ContentPages.vue'),
                meta: {
                    breadcrumbs: [
                        {
                            name: 'menu.projects',
                            link: 'url.projects'
                        },
                        {
                            key: 'project_name',
                            link: 'url.project/:project_code'
                        },
                        {
                            name: 'titles.content_pages',
                        }
                    ],
                    title: {i18n: "titles.content_pages"}
                },
            },
            {
                path: locales.cz.url.overview+"/:page_num",
                alias:[ locales.en.url.overview+"/:page_num"],
                name: "content_pages_overview",
                component: () => import(/* webpackChunkName: "content-pages" */'@admin/views/cms/ContentPages.vue'),
                meta: {
                    breadcrumbs: [
                        {
                            name: 'menu.projects',
                            link: 'url.projects'
                        },
                        {
                            key: 'project_name',
                            link: 'url.project/:project_code'
                        },
                        {
                            name: 'titles.content_pages',
                        }
                    ],
                    title: {i18n: "titles.content_pages"}
                },
            },
            {
                path: ":page_code",
                component: () => import(/* webpackChunkName: "content-page" */'@admin/views/cms/ContentPage.vue'),
                name: "page_edit",
                meta: {
                    breadcrumbs: [
                        {
                            name: 'menu.projects',
                            link: 'url.projects'
                        },
                        {
                            key: 'project_name',
                            link: 'url.project/:project_code'
                        },
                        {
                            name: 'titles.content_pages',
                            link: 'url.project/:project_code/url.content_pages'
                        },
                        {
                            store: "page_editor/getPageName"
                        }
                    ],
                    title: {store: "page_editor/getPageName"}
                },
            },
            {
                path: locales.cz.url.content_block+"/:content/:page_num",
                alias:[ locales.en.url.content_block+"/:content/:page_num"],
                name: "content_pages_block",
                component: () => import(/* webpackChunkName: "content-pages" */'@admin/views/cms/ContentPages.vue'),
                meta: {
                    breadcrumbs: [
                        {
                            name: 'menu.projects',
                            link: 'url.projects'
                        },
                        {
                            key: 'project_name',
                            link: 'url.project/:project_code'
                        },
                        {
                            name: 'titles.content_pages',
                            link: 'url.project/:project_code/url.content_pages'
                        }
                    ],
                    title: {i18n: "titles.content_pages"}
                },
            },
        ]
    };
}