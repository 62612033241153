import { httpService } from "@common/services/http.service";
import config from 'config';

export default function(router){
    const axiosConfig = {
        baseURL: config.apiUrl,
    }
    const httpClient = httpService(axiosConfig);

    const relUrl = ({code = false} = {}) => {
        let url = router.currentRoute.value.params.lang+'/v1/projects';
        if(code){
            return url+'/'+code
        }
        return url;
    };

    const state = {
        project: {},
        loading: 0,
        info: {},
        infoLoading: 0,
    }

    const getters = {
        getProject: (state) => state.project,
        isLoaded: (state) => state.loading === 0,
        isError: (state) => state.loading === -1,
        getProjectName: (state) => {
            if(Object.prototype.hasOwnProperty.call(state.project,'name')){
                return state.project.name;
            }
            return '';
        },
        getInfo: (state) => state.info,
        isInfoLoaded: (state) => state.infoLoading === 0,
        isInfoError: (state) => state.infoLoading === -1,
        getExtensionData: (state) => (key) => {
            if(!state.project || !Object.prototype.hasOwnProperty.call(state.project,'extensions')){
                return null;
            } 
            for(let extension of state.project.extensions){
                if(extension.item_key === key){
                    return extension;
                }
            }
            return null;
        },
        getClassList: (state,getters) => (type) => {
            let _extension = getters.getExtensionData('cms_base_1');
            if(!_extension ||
                !Object.prototype.hasOwnProperty.call(_extension,'classes') || 
                !Object.prototype.hasOwnProperty.call(_extension.classes,type) || 
                !Array.isArray(_extension.classes[type]) ||
                _extension.classes[type].length === 0 ){
                    return null;
            }
            return [
                {
                    title: '',
                    value: '',
                },
                ..._extension.classes[type]
            ];
        }
    }

    const actions = {
        fetchProject({ commit,dispatch },{ project_code }){
            if(!project_code){
                return;
            }
            commit('loading',1);
            return httpClient.get(relUrl({code: project_code})+"?expand=extensions")
                .then(response => {
                    commit('setProject',response.data);
                    commit('loading',0);
                },
                error => {
                    commit('loading',-1);
                    dispatch('alert/error', error, { root: true });
                })
        },
        fetchInfo({ commit,dispatch,state }){
            if(!state.project.item_key){
                commit('setInfo',{});
                return;
            }
            commit('infoLoading',1);
            return httpClient.get(relUrl({code: 'info/'+state.project.item_key}))
                .then(response => {
                    commit('setInfo',response.data);
                    commit('infoLoading',0);
                },
                error => {
                    commit('infoLoading',-1);
                    dispatch('alert/error', error, { root: true });
                })
        },
    }

    const mutations = {
        setProject: (state,project) => state.project = project,
        loading: (state,status) => state.loading = status,
        infoLoading: (state,status) => state.infoLoading = status,
        setInfo: (state,info) => state.info = info,
    }

    return {
        namespaced: true,
        state: state,
        getters: getters,
        actions: actions,
        mutations: mutations
    };

}