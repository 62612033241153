import { baseCRUDStore } from '@common/store/crud.module';

export default function(router){
    const relUrl = () => {
        let url = router.currentRoute.value.params.lang+'/'+router.currentRoute.value.params.project_code+'/v1/cms/menu';
        return url;
    };

    const baseCRUD = baseCRUDStore({
        relUrl: relUrl,
        item_key: "menu_key", 
        item_view: "menu-view", 
        item_api_key: "item_key", 
        router: router,
    })

    const state = {
        ...baseCRUD.state
    };

    const getters = {
        ...baseCRUD.getters,
        getMenuName: (state,getters) => {
            if(getters['getActiveItem']){
                return getters['getActiveItem'].name;
            }
            return '';
        },
    }

    const actions = {
        fetchItems: baseCRUD.actions.fetchItems,
    };

    const mutations = {
        ...baseCRUD.mutations
    };

    return {
        namespaced: true,
        state,
        getters,
        actions,
        mutations
    };
}
