<template>
    <BaseIcon class="admin-icon"></BaseIcon>
</template>

<script>
export default {
    name: "AdminIcon"
}
</script>

<style>
.icon.content_block{
    background-image: url('../../assets/icons/content_block.svg');
}
.icon.blocks{
    background-image: url('../../assets/icons/blocks.svg');
}
.icon.add_block{
    background-image: url('../../assets/icons/add_block.svg');
}
.icon.edit_page{
    background-image: url('../../assets/icons/edit_page.svg');
}

</style>