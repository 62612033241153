export default function( locales ){
    return {
        path: locales.cz.url.budget,
        alias: locales.en.url.budget,
        name: "webur-budget",
        component: () => import(/* webpackChunkName: "budget" */'@admin/views/webur/Budget.vue'),
        meta: {
            breadcrumbs: [
                {
                    name: 'menu.projects',
                    link: 'url.projects'
                },
                {
                    key: 'project_name',
                    link: 'url.project/:project_code'
                },
                {
                    name: 'titles.budget_view',
                }
            ],
            title: {i18n: "titles.budget_view"}
        },
    };
}