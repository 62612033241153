<template>
    <router-view v-bind="$attrs" keep-alive/>
</template>

<script>
import config from 'config'
import setupHeader from "@common/composable/header";
import { watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';


export default {
    name: "App",
    setup(){
        const router = useRouter();
        const i18n = useI18n();
        const store = useStore();

        const {  setMetaData  } = setupHeader(router,i18n,store,config);
        watch(() => store.getters['project/getProjectName'],() => setMetaData(router,i18n,store,config));
        watch(() => store.getters['content/getContentName'],() => setMetaData(router,i18n,store,config));
        watch(() => store.getters['content_page/getPageName'],() => setMetaData(router,i18n,store,config));

        
    }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
    min-height: 100vh;
    color: #163452;
    color: var(--dark-color);
    background-color:  #c2d2e2;
    background-color: var(--light-color);
}


:root{
    --primary-color: #177542;
    --dark-color:#344b1b;
    --light-color: #e7eae3;
    --secondary-color:#f1bd80;
    --button-color:#c9c9c9;
    --border-color:#bbbbbb;
    --transition-ease: 0.5s ease;
    --transition-opacity: opacity var(--transition-ease);

    --button-size: 1.8rem;

    --primary-font: Avenir, Helvetica, Arial, sans-serif;
}


*{
    box-sizing: border-box;
}


/* implementation for old browsers*/
article, aside, footer, header, nav, section {
    display: block;
}

body{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

a{
    text-decoration: none;
    transition: var(--transition-opacity);
}
ul{
    list-style-type: none;
}

</style>
