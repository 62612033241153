import { httpService } from "@common/services/http.service";
import config from 'config'

const goToItemPage = ({code,router,item_view,item_key}) => {
    if(item_view === ''){
        return;
    }
    router.push({ name: item_view, params: { [item_key]: code } });        
}
const goToIndex = ({router,index_name}) => {
    router.push({ name: index_name });  

}

export function baseCRUDStore({
    router,
    relUrl,
    item_key = '', // "client_code" - is in router
    item_view = '', // "client-view" - is in router
    index_name = 'home', // in router
    item_api_key = '', // "code"
    afterUpdate = ({code,router,item_view}) => goToItemPage({code,router,item_view,item_key}),
    afterCreate = ({code,router,item_view}) => goToItemPage({code,router,item_view,item_key}),
    afterDelete = ({router,index_name}) => goToIndex({router,index_name}),
}){
    const axiosConfig = {
        baseURL: config.apiUrl,
    }
    const httpClient = httpService(axiosConfig);

    const indexRelUrl = ({page = 1, state ,query = {}}) => {
        let url = relUrl({state}); 
        url += '?';
        let filters = state.filters;
        for (let key in filters) {
            if (!Object.prototype.hasOwnProperty.call(filters,key)) continue; // to remove js object default properties

            if(filters[key].exact){
                url += `filter[${key}]=${filters[key].value}&`;
            } else {
                url += `filter[${key}][like]=${filters[key].value}&`;
            }
            
        }
        for (let key in query) {
            if (!Object.prototype.hasOwnProperty.call(query,key)) continue; // to remove js object default properties

            url += `${key}=${query[key]}&`;
        }
        if(page > 1){
            url += `page=${page}&`;
        }
        url = url.slice(0,-1);
        // console.log(url)
        return url;
    }


    const state = {
        items: [],
        loading: 0,
        storedData: {},
        filters: {},
        selected_row: -1,
        page_count: 0,
        current_page: 0,
        query: {},
    }

    const getters = {
        getItems: (state) => state.items,
        getPageCount: (state) => state.page_count,
        getCurrentPageNum: (state) => state.current_page,
        getActiveItem: (state) => {
            if(router.currentRoute.value.params[item_key]){
                const code = router.currentRoute.value.params[item_key];
                return state.items.find(item => item[item_api_key] === code);
            }
            return null;
        },
        getSelectedRecord: (state) => {
            if(state.selected_row >= 0 && state.items.length > state.selected_row){
                return state.items[state.selected_row];
            }
            return {};
        },
        isLoaded: (state) => state.loading === 0,
        isError: (state) => state.loading === -1,
        getFilters: state => state.filters,
        getQuery: state => state.query,
    }

    const actions = {
        fetchItems({ commit,dispatch,state },{page = 1,filter = false,query = {}}){
            if(!filter){
                commit('clearFilters');
            }
            if(!(query && Object.keys(query).length === 0 && query.constructor === Object)){
                commit('setQuery',query)
            } else if(query === null ){
                commit('setQuery',{})
            }
            commit('loading',1);
            return httpClient.get(indexRelUrl({page,state,query: state.query}))
                .then(response => {
                    commit('setItems',response.data);
                    commit('setPagination',{current: response.headers['x-pagination-current-page'], count: response.headers['x-pagination-page-count']})
                    commit('loading',0);
                },
                error => {
                    commit('loading',-1);
                    dispatch('alert/error', error, { root: true });
                })
        },
        fetchItem({ commit,dispatch }){
            if(!router.currentRoute.value.params[item_key]){
                console.log("Unknown item");
                return;
            }         
            commit('loading',1);   
            const code = router.currentRoute.value.params[item_key];
            return httpClient.get(relUrl()+'/'+code)
                .then(response => {
                    commit('setItems',[response.data]);
                    commit('setSelectedRow',0)
                    commit('loading',0);
                },
                error => {
                    commit('loading',-1);
                    dispatch('alert/error', error, { root: true });
                })
        },
        updateItem({ commit,getters,dispatch },{ data }){
            let item = getters['getActiveItem'];
            if(!item){
                dispatch('alert/error', "Unknown item", { root: true });
                return;
            }            
            if(!data || Object.keys(data).length === 0){
                afterUpdate({code: item[item_api_key],router,item_view,item_key});
                return;
            }
            commit('clearStoredData');
            // commit('loading',1);
            return httpClient.put(relUrl({code: item[item_api_key]}),data)
                .then(response => {
                    commit('setItem',{code: item[item_api_key],new_data: response.data});
                    // commit('loading',0);
                    afterUpdate({code: item[item_api_key],router,item_view,item_key});
                },
                error => {
                    // commit('loading',-1);
                    dispatch('alert/error', error, { root: true });
                });
        },
        deleteItem({ commit,getters,dispatch }){
            let item = getters['getActiveItem'];
            if(!item){
                dispatch('alert/error', "Unknown item", { root: true });
                return;
            }  
            commit('clearStoredData');
            // commit('loading',1);
            return httpClient.delete(relUrl({code: item[item_api_key]}))
                .then(() => {
                    commit('removeItem',item[item_api_key]);
                    // commit('loading',0);
                    afterDelete({router,index_name});
                },
                error => {
                    // commit('loading',-1);
                    dispatch('alert/error', error, { root: true });
                });
        },
        deleteItemByCode({ commit,dispatch },{code}){
            return httpClient.delete(relUrl({code: code}))
                .then(() => {
                    commit('removeItem',code);
                },
                error => {
                    dispatch('alert/error', error, { root: true });
                });
        },
        createItem({ commit,dispatch },{data}){         
            if(!data || Object.keys(data).length === 0){
                return;
            }
            commit('clearStoredData');
            // commit('loading',1);
            return httpClient.post(relUrl(),data)
                .then(response => {
                    commit('addItem',{data: response.data});
                    // commit('loading',0);
                    afterCreate({code: response.data[item_api_key],router,item_view,item_key});
                },
                error => {
                    // commit('loading',-1);
                    dispatch('alert/error', error, { root: true });
                });

        },
        custom_action({commit,dispatch,getters},{ custom_code,action }){
            let item = getters['getActiveItem'];
            if(!item){
                dispatch('alert/error', "Unknown item", { root: true });
                return;
            }

            return httpClient.get(relUrl({code: item[item_api_key]})+'/'+action+'/'+custom_code)
                .then(response => {
                    commit('setItem',{code: item[item_api_key],new_data: response.data});
                    commit('loading',0);
                    goToItemPage(item[item_api_key]);
                },
                error => {
                    commit('loading',-1);
                    dispatch('alert/error', error, { root: true });
                })
        },
        filter: ({commit,dispatch},{key,value,exact}) => {
            commit('setFilter',{key,value,exact});
            dispatch('fetchItems',{filter: true});
        }
    }

    const mutations = {
        setItems: (state,items) => state.items = items,
        setItem: (state,{ code,new_data}) => {
            let array = [];
            state.items.forEach(item => {
                if(item[item_api_key] === code){
                    array.push(new_data)
                } else {
                    array.push(item)
                }
            });
            state.items = array;
        },
        addItem: (state,{data}) => state.items.push(data),
        loading: (state,status) => state.loading = status,
        removeItem: (state,code) => {
            let index = state.items.findIndex((item) => item[item_api_key] === code);
            if (index !== -1) {
                state.items.splice(index, 1);
            }
        },
        storeData: (state,data) => state.storedData = data,
        clearStoredData: (state) => state.storedData = {},
        setFilter: (state,{key,value,exact = false}) => {
            if(value.trim() === "" && Object.prototype.hasOwnProperty.call(state.filters,key)){
                delete state.filters[key];
                return;
            }
            state.filters[key] = {exact,value};
        },
        clearFilters: (state) => state.filters = {},
        setSelectedRow: (state,index) => {
            state.selected_row = index;
        },
        setPagination: (state,{count,current}) => {
            state.page_count = count;
            state.current_page = current;
        },
        setQuery: (state,query) => state.query = query,
    }
    
    return {
        namespaced: true,
        state: state,
        getters: getters,
        actions: actions,
        mutations: mutations,
        httpClient
    };

}