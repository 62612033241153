<template>
    <div class="main-header">
        <RouterLinkLocal class="button top-logo" :link="''"><img alt="AgneWeb logo" src="@admin/assets/logo.png"><span>{{ $t('titles.administration') }}</span></RouterLinkLocal>
        <div class="button only-mobile" @click="$emit('toggle-menu')">
            <div class="hamburger" ></div>
        </div>
        <div class="stretch"></div>
        <div class="button logout" @click="logout">{{ $t('autentification.logout') }}</div>
    </div>
</template>

<script>
import { useStore } from 'vuex';

export default {
    name: "MainHeader",
    emits: ['toggle-menu'],
    setup(){
        const store = useStore();
        const logout = () => store.dispatch("account/logout");

        return {
            logout
        }
    }
}
</script>

<style scoped>

.main-header {
    white-space: nowrap;
    background-color: #8bbef1;
    background-color: var(--primary-color);
    box-shadow: 0px 2px 8px 1px grey;
    margin-bottom: 2px;
    text-align: right;
    display: flex;
    position: relative;
    align-items: center;
    height: 2.5rem;
    color: white;
    color: var(--light-color);
    text-shadow: 1px 1px 4px #000000;
}

.main-header a {
  font-weight: bold;
  color: white;
  color: var(--light-color);
  text-shadow: 1px 1px 4px #000000;
}

.main-header a img{
    border: 2px solid white;
    border: 2px solid var(--light-color);
    border-radius: 50%;
}

/* .main-header a.router-link-exact-active {
  color: #42b983;
} */
.button{
    cursor: pointer;
    padding: 0.5rem 1rem;
    transition:  background-color var(--transition-ease);
    height: 100%;
    margin-right: 0.5rem;
    overflow: hidden;
}
.top-logo{
    text-decoration: none;
}
.top-logo>span{
    position: relative;
    top: -0.3rem;
}

.top-logo img{
     height: 100%;
    margin-right: 0.5rem
}
.logout{
    padding: 0.8rem 1rem;
}

.button:hover{
    /* opacity: 0.7; */
    background-color:  #365446;
}
.hamburger, .hamburger::before, .hamburger::after{
    position: relative;
    width: 1.5rem;
    height: 0.25rem;
    border-radius: 4px;
    background-color:var(--dark-color);
    display: block;
    content: '';
}
.hamburger{
    top: 0.6rem;
}
.hamburger::before{
    top: -0.45rem;
}
.hamburger::after{
    top: 0.25rem;
}
.stretch{
    flex-grow: 1;
}

@media only screen and (min-width: 800px) {
    .hamburger{
        display: none;
    }
    .only-mobile{
        display: none;
    }
}

</style>
