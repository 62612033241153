<template>
    <template v-bind="$attrs" v-if="loaded">
        <slot></slot>
    </template>
    <li class="loader" v-else-if="error">
        <BaseIcon class="error" />
    </li>
    <li class="loader" v-else>
        <BaseLoader />
    </li>
</template>

<script>
export default {
    name: "LoadedListContent",
    props: {
        loaded: Boolean,
        error: Boolean
    }

}
</script>

<style scoped>
.loader{
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

</style>